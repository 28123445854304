.filters-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.filter-input {
    flex: 1;
    margin: 0 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    background-color: #fff;
    text-align: center;
    transition: all 0.3s ease;
}

.filter-input::placeholder {
    text-align: center;
    color: #aaa;
}

.filter-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

@media (max-width: 768px) {
    .filters-container {
        flex-direction: column;
        align-items: stretch;
    }

    .filter-input {
        margin: 10px 0;
    }
}
