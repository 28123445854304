.page-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding: 20px;
    overflow-x: auto;
    min-height: 100%;
}

.data-table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 30px;
    border-collapse: collapse;
    table-layout: fixed;
}

.data-table th, .data-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: center;
    word-wrap: break-word;
}

.data-table th {
    font-size: 1.2em;
    font-weight: bold;
    border-bottom: 2px solid #ddd;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 115px;
    width: calc(100% - 131px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #003366;
    color: #fff;
    padding: 10px 20px;
}

.footer p {
    align-items: center;
    margin: 0;
}

.pagination {
    display: flex;
    gap: 10px;
    align-items: center;
}

.pagination span {
    margin-right: 10px;
}

.pagination button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #0055a5;
    color: #fff;
    transition: background-color 0.3s;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #004080;
}

.filters {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.filters input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 200px;
}
