.edit-grade {
    border: 2px solid black;
}

.reactgrid {
    background-color: #ffffff;
    border: 1px solid #000000;
}

.reactgrid-header-cell {
    font-weight: bold;
    background-color: #007BFF;
    color: white;
}

.reactgrid-row:hover {
    background-color: #ff0000;
    cursor: pointer;
}

.reactgrid-row.selected {
    background-color: #007BFF;
    color: white;
}

.reactgrid-cell.active {
    background-color: #007BFF;
    color: white;
    border: 1px solid #0056b3;
}