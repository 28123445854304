/* Sidebar CSS */
.sidebar {
  width: 4.5rem;
  height: calc(100vh - 2rem);
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  position: fixed;
  border-radius: 0.5rem;
}
