// Variables de couleurs
$header-bg-color: #d3d3d3; // Gris clair pour l'en-tête
$edit-cell-border-color: #000000; // Bordure noire pour les cellules éditables
$border-color: #f8f9fa;  // Gris clair pour les bordures
$row-bg-light: #f8f9fa;  // Gris clair pour les lignes impaires
$row-bg-white: #ffffff;  // Blanc pour les lignes paires

/* Container parent qui utilise Flexbox pour centrer le tableau */
.reactgrid-container {
  display: flex;
  justify-content: center;   /* Centre horizontalement */
  align-items: center;       /* Centre verticalement */
  width: 100%;               /* Prendre toute la largeur disponible */
  padding: 10px;             /* Optionnel : Ajouter un peu de padding pour éviter que le contenu ne touche les bords */
  box-sizing: border-box;    /* Inclut padding et bordures dans la largeur totale */
}

/* Le tableau ReactGrid */
.reactgrid {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;  /* S'assurer que le tableau prend toute la largeur disponible */
  background-color: transparent;
  border: 1px solid $border-color;

  
  /* En-têtes de la grille */
  &-header-cell {
    font-weight: bold;
    text-align: center;
    background-color: $header-bg-color;
    border: 1px solid $border-color;
  }

  /* Cellules générales */
  .reactgrid-cell {
    border: 1px solid $border-color;
    text-align: center;  /* Centrer le contenu des cellules */

    /* Hover sur une cellule */
    &:hover {
      background-color: $row-bg-white;
      color: $row-bg-white;
      cursor: pointer;
      transition: background-color 0.2s ease;
    }
  }

  /* Validation visuelle */
  .validation-column {
    font-weight: bold;
    font-size: 1.2em;
  }

  /* Alternance des lignes */
  .reactgrid-row:nth-child(even) {
    background-color: $row-bg-light; /* Gris clair pour les lignes paires */
  }
  .reactgrid-row:nth-child(odd) {
    background-color: $row-bg-white; /* Blanc pour les lignes impaires */
  }

  .reactgrid .group-cell,
  .reactgrid .validation-cell {
    background-color: #f0f0f0;  /* Gris clair pour les rendre non modifiables visuellement */
    cursor: not-allowed;        /* Curseur en forme d'interdiction */
  }

  /* Personnalisation de l'en-tête */
  .reactgrid-header-cell {
    background-color: $header-bg-color;
    border: 1px solid $border-color;
    font-weight: bold;
    text-align: center;
    cursor: not-allowed;  
  }

  /* Cellules éditables avec bordure noire */
  .edit-grade {
    border: 1px solid $edit-cell-border-color; /* Bordure noire pour les cellules éditables */
  }
}